import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { connect } from 'react-redux';
import Login from './views/Login/Login'
import { CSpinner, useColorModes } from '@coreui/react'
import './scss/style.scss'
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))


const App = (props) => {
  const [mode, setMode] = useState(props.Header.theme)
  const { isColorModeSet, setColorMode } = useColorModes('coreui-free-react-admin-template-theme')
  const storedTheme = useSelector((state) => state.theme)


  useEffect(() => {
    setMode(props.Header.theme)
  }, [props.Header.theme])



  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1])
    const theme = urlParams.get('theme') && urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0]
    if (theme) {
      setColorMode(theme)
    }
    if (isColorModeSet()) {
      return
    }
    setColorMode(storedTheme)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (

    <HashRouter>
      <Suspense
        fallback={
          <div className="pt-3 text-center">
            <CSpinner color="primary" variant="grow" />
          </div>
        }
      >
        <Routes>
          <Route exact path="/Login" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Home" element={<DefaultLayout mode={mode} />} />
        </Routes>
      </Suspense>
    </HashRouter>

  )
}
function mapStateToProps(state) {
  const { Header } = state; // Destructure header and theme from state
  return { Header }; // Return header and theme as props
}

export default connect(mapStateToProps)(App);
