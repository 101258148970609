import { createSlice } from '@reduxjs/toolkit';

let color;

try {
    const reduxState = localStorage.getItem("reduxState");
    if (reduxState) {
        const parsedState = JSON.parse(reduxState);
        if (parsedState.Header && parsedState.Header.theme) {
            color = parsedState.Header.theme;
        }
    }
} catch (error) {
    // console.error('Error parsing or accessing session storage data:', error);
}

const initialState = {
    sidebarShow: true,
    theme: color,
    sidebarUnfoldable: false
};


const Header = createSlice({
    name: 'Header',
    initialState,
    reducers: {
        changeSidebar: (state, action) => {
            const { payload } = action;
            return { ...state, ...payload };
        },
        changeTheme: (state, action) => {
            const { payload } = action;
            return { ...state, ...payload };
        }
    }
});

export default Header.reducer;
export const { changeSidebar, changeTheme } = Header.actions;
