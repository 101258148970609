import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projectName: "",
  projectDescription: "",
  selectedBuildingType: "",
  contactFirstName: "",
  contactMiddleName: "",
  contactLastName: "",
  email: "",
  phoneNumber: "",
  pipelineStatus: "",
  landArea: "",
  buildUpArea: "",
  floorsToBeConstructed: "",
  clientBudget: "",
  approvedBudget: "",
  plannedStartDate: "",
  plannedEndDate: "",
  tentativeDuration: "",
  addresses: {
    buildingNumber: "",
    unitNumber: "",
    street: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  },
};

const ProjectFormSlice = createSlice({
  name: "ProjectFormData",
  initialState,
  reducers: {
    editProjectFormData: (state, action) => {
      const { id, value } = action.payload;
      if (state.addresses.hasOwnProperty(id)) {
        return {
          ...state,
          addresses: {
            ...state.addresses,
            [id]: value,
          },
        };
      } else {
        return { ...state, [id]: value };
      }
    },
    ClearProjectFormData: (state, action) => {
      return initialState;
    },
    CompleteUpdate: (state, action) => {
      return action.payload;
    },
  },
});

export default ProjectFormSlice.reducer;
export const { editProjectFormData, ClearProjectFormData, CompleteUpdate } =
  ProjectFormSlice.actions;
