import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserAuthentication } from "./loginController";
import { useNavigate } from "react-router-dom";
import "./login.css";
import { CSpinner } from "@coreui/react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    email: "",
    password: "",
  });
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const userAuthentication = useSelector((state) => state.userAuthentication);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      navigate("/Login");
    } else {
      if (userAuthentication.isUserValid === "welcometobo365") {
        let parsedToken = JSON.parse(token);
        if (parsedToken.Id) {
          navigate("/dashboard");
        }
      }
    }
  }, [userAuthentication.isUserValid]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeHandler = (event) => {
    const { id, value } = event.target;

    if (id === "email") {
      setEmailError("");
    } else if (id === "password") {
      setPasswordError("");
    }
    setUserDetails({ ...userDetails, [id]: value });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      userVerification();
    }
  };

  const userVerification = async () => {
    if (validate()) {
      dispatch(getUserAuthentication(userDetails));
    }
  };

  const validate = () => {
    let isValid = true;
    setEmailError("");
    setPasswordError("");

    // Check if email is empty
    if (!userDetails.email) {
      setEmailError("Email is required.");
      isValid = false;
    } else {
      // Check if email format is valid using regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(userDetails.email)) {
        setEmailError("Invalid email format.");
        isValid = false;
      }
    }

    // Check if password is empty
    if (!userDetails.password) {
      setPasswordError("Password is required.");
      isValid = false;
    }

    return isValid;
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <section className="root-s">
      <div className="login-root">
        <div
          className="box-root flex-flex flex-direction--column"
          style={{ minHeight: "100vh", flexGrow: 1 }}
        >
          <div className="loginbackground box-background--white padding-top--64">
            <div className="loginbackground-gridContainer">
              <div
                className="box-root flex-flex"
                style={{ gridArea: "top / start / 8 / end" }}
              >
                <div
                  className="box-root"
                  style={{
                    backgroundImage:
                      "linear-gradient(white 0%, rgb(247, 250, 252) 33%)",
                    flexGrow: 1,
                  }}
                ></div>
              </div>
              <div
                className="box-root flex-flex"
                style={{ gridArea: "4 / 2 / auto / 5" }}
              >
                <div
                  className="box-root box-divider--light-all-2 animationLeftRight tans3s"
                  style={{ flexGrow: 1 }}
                ></div>
              </div>
              <div
                className="box-root flex-flex"
                style={{ gridArea: "6 / start / auto / 2" }}
              >
                <div
                  className="box-root box-background--blue800"
                  style={{ flexGrow: 1 }}
                ></div>
              </div>
              <div
                className="box-root flex-flex"
                style={{ gridArea: "7 / start / auto / 4" }}
              >
                <div
                  className="box-root box-background--blue animationLeftRight"
                  style={{ flexGrow: 1 }}
                ></div>
              </div>
              <div
                className="box-root flex-flex"
                style={{ gridArea: "8 / 4 / auto / 6" }}
              >
                <div
                  className="box-root box-background--gray100 animationLeftRight tans3s"
                  style={{ flexGrow: 1 }}
                ></div>
              </div>
              <div
                className="box-root flex-flex"
                style={{ gridArea: "2 / 15 / auto / end" }}
              >
                <div
                  className="box-root box-background--cyan200 animationRightLeft tans4s"
                  style={{ flexGrow: 1 }}
                ></div>
              </div>
              <div
                className="box-root flex-flex"
                style={{ gridArea: "3 / 14 / auto / end" }}
              >
                <div
                  className="box-root box-background--blue animationRightLeft"
                  style={{ flexGrow: 1 }}
                ></div>
              </div>
              <div
                className="box-root flex-flex"
                style={{ gridArea: "4 / 17 / auto / 20" }}
              >
                <div
                  className="box-root box-background--gray200 animationRightLeft"
                  style={{ flexGrow: 1 }}
                ></div>
              </div>
              <div
                className="box-root flex-flex"
                style={{ gridArea: "5 / 14 / auto / 17" }}
              >
                <div
                  className="box-root box-divider--light-all-2 animationRightLeft tans3s"
                  style={{ flexGrow: 1 }}
                ></div>
              </div>
            </div>
          </div>

          <div
            className="box-root padding-top--24 flex-flex flex-direction--column"
            style={{ flexGrow: 1, zIndex: 9 }}
          >
            <div className="box-root  flex-flex flex-justifyContent--center">
              {userAuthentication.loading && <CSpinner variant="grow" />}
            </div>
            <div className="box-root padding-top--48 padding-bottom--24 flex-flex flex-justifyContent--center">
              <br />
              <br />
              <h1>User Login</h1>
            </div>
            <div className="box-root flex-flex flex-justifyContent--center error"></div>

            <div className="formbg-outer">
              <div className="formbg">
                <div className="formbg-inner padding-horizontal--48">
                  <span className="padding-bottom--15 span-s">
                    Login to your account
                  </span>

                  {userAuthentication.error && (
                    <div className="error" style={{ textAlign: "center" }}>
                      {userAuthentication.error}
                    </div>
                  )}
                  <div id="stripe-login">
                    <div className="field padding-bottom--24">
                      <div className="col-md-12">
                        <label
                          htmlFor="validationServer02"
                          className="form-label"
                        >
                          Email
                        </label>
                        <input
                          id="email"
                          type="email"
                          className={`form-control ${
                            emailError && "is-invalid"
                          } `}
                          value={userDetails.email}
                          required
                          onChange={onChangeHandler}
                          onKeyPress={handleKeyPress}
                          placeholder="name@example.com"
                        />
                        {!emailError ? (
                          ""
                        ) : (
                          <div className="invalid-feedback">{emailError}</div>
                        )}
                      </div>
                    </div>

                    <div className="field padding-bottom--24">
                      <div className="col-md-12">
                        <label
                          htmlFor="validationServer02"
                          className="form-label"
                        >
                          Password
                        </label>
                        <div
                          className={`password-container ${
                            passwordError ? "password-container-error" : ""
                          }`}
                        >
                          <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            label="Password"
                            placeholder="Password"
                            onChange={onChangeHandler}
                            onKeyPress={handleKeyPress}
                            name="password"
                            className={`form-control ${
                              passwordError && "is-invalid"
                            } `}
                          />
                          <button
                            type="button"
                            className="password-toggle"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? (
                              <FaEyeSlash color="lightgrey" />
                            ) : (
                              <FaEye color="lightgrey" />
                            )}
                          </button>
                          {!passwordError ? (
                            ""
                          ) : (
                            <div className="invalid-feedback">
                              {passwordError}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="field padding-bottom--24">
                      <button
                        style={{ background: "blue", marginTop: "1rem" }}
                        onClick={userVerification}
                        className="sumbit-class field-input-s"
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
