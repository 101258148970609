import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'
import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist'
import loginController from "../src/views/Login/loginController";
import HeaderController from "./components/HeaderController";
import ProjectController from "./views/Project/ProjectController";

const reducers = combineReducers({
  userAuthentication: loginController,
  Header: HeaderController,
  ProjectFormData: ProjectController,
});

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, reducers);


const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;