import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilBuilding,
  cilPuzzle,
  cilSpeedometer,
  cilCalendar,
  cilLineSpacing,
  cilMap,
  cilStorage,
  cilGem,
} from "@coreui/icons";
import { CNavGroup, CNavItem } from "@coreui/react";

const menuCache = new Map();

function getMenu(menuArr) {
  const key = JSON.stringify(menuArr);
  if (menuCache.has(key)) {
    return menuCache.get(key);
  }
  let res = [];
  menuArr?.length > 0 &&
    menuArr.forEach((obj) => {
      if (obj.Component === "CNavItem") {
        res.push({
          icon: getFullIcon(obj.Icon),
          component: CNavItem,
          name: obj.Name,
          to: obj.To,
          badge: obj.Badge,
        });
      } else {
        res.push({
          icon: getFullIcon(obj.Icon),
          component: CNavGroup,
          items: getMenu(obj.Items),
          name: obj.Name,
          to: obj.To,
          badge: obj.Badge,
        });
      }
    });

  // Cache the result
  menuCache.set(key, res);
  return res;
}

export function getNav(items) {
  if (Array.isArray(items) && items?.length === 0) return [];
  return getMenu(items);
}

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

function getFullIcon(iconName) {
  let icon;
  switch (iconName) {
    case "cilBuilding":
      icon = cilBuilding;
      break;
    case "cilPuzzle":
      icon = cilPuzzle;
      break;
    case "cilSpeedometer":
      icon = cilSpeedometer;
      break;
    case "cilCalendar":
      icon = cilCalendar;
      break;
    case "cilLineSpacing":
      icon = cilLineSpacing;
      break;
    case "cilMap":
      icon = cilMap;
      break;
    case "cilStorage":
      icon = cilStorage;
      break;
    case "cilGem":
      icon = cilGem;
      break;
    default:
      return null;
  }

  return <CIcon icon={icon} customClassName="nav-icon" />;
}
