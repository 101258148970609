import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { parseJwt } from "src/_nav";
import { HTTP_POST, HTTP_GET } from "src/http";

// Initial state of the user login
const initialState = {
  loading: false,
  isUserValid: false,
  error: "",
  token: "",
  sideMenu: [],
};

// Async thunk to fetch user authentication from an API
export const getUserAuthentication = createAsyncThunk(
  "userAuthentication",
  async (loginDetails = {}) => {
    console.log(loginDetails); // Contains login ID or password entered by user, send this to API as payload
    try {
      const response = await HTTP_POST(
        `${process.env.REACT_APP_API_KEY}/Auth/Login`,
        {
          email: loginDetails.email,
          password: loginDetails.password,
        }
      );
      const responseForMenu = await HTTP_GET(
        `${process.env.REACT_APP_API_KEY}/Menu`
      );
      let data = {
        token: response.data,
        menu: responseForMenu?.data || [],
      };
      return data;
    } catch (error) {
      throw Error("Failed to authenticate user.");
    }
  }
);

// Slice for user authentication reducer
const userAuthenticationReducer = createSlice({
  name: "userAuthentication",
  initialState,
  reducers: {
    resetState: (state) => {
      localStorage.clear();
      sessionStorage.clear();
      state.loading = false;
      state.isUserValid = false;
      state.error = "";
      state.token = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserAuthentication.pending, (state) => {
      state.loading = true;
      state.error = "";
      state.isUserValid = false;
    });
    builder.addCase(getUserAuthentication.fulfilled, (state, action) => {
      if (
        action?.payload?.token === "" ||
        action?.payload?.token === "User is  not correct"
      ) {
        state.loading = false;
        state.isUserValid = false;
        state.error = "Not Authorized";
        state.token = "";
      } else {
        state.loading = false;
        state.isUserValid = "welcometobo365";
        state.error = "";
        let parsedToken = parseJwt(action?.payload?.token);
        state.token = parsedToken;
        state.sideMenu = action?.payload?.menu;
        let token = parsedToken;
        sessionStorage.setItem('token', JSON.stringify(token) );
        sessionStorage.setItem('FullToken', action?.payload?.token );
      }
    });
    builder.addCase(getUserAuthentication.rejected, (state, action) => {
      state.loading = false;
      state.isUserValid = false;
      state.error = action.error.message;
      state.token = "";
    });
  },
});

export default userAuthenticationReducer.reducer;

export const { resetState } = userAuthenticationReducer.actions;
